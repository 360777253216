<template>
	<div class="animate__animated animate__fadeIn">
		<top-bar :shadow="true">
			<div class="top-box">
				<img :src="logoImg" class="logo">
				<div class="menus-box" :style="fontColor">
					<div class="menus" v-for="(m,mi) in menus" :style="active == m.path ? {fontWeight:600} : ''" :key="mi" @mouseover="changeOver(m)" :class="m.hover ? 'hover-bg' : '' " @mouseleave="mouseLeave(m)" @click="changePath(m)">
						{{m.name}}
						<div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
						<div class="se-me hover-bg" v-if="m.hover" >
							<div class="child" :style="fontColor" v-for="(c,ci) in m.child" :key="ci" @click.stop="changePath(c)">
								{{c.name}}
							</div> 
						</div>
					</div>
				</div>
			</div>
		</top-bar>
		<div class="header-tag">
			<span>解决方案 - 项目案例</span>
			<span style="color: #666;">--CO₂矿化养护混凝土示范工程</span>
		</div>
		<div class="body-box">
			<div class="left-box">
<!-- 				<div class="tag-title">其他案例</div> -->
				<div class="dot"></div>
			</div>
			<div class="right-box">
				<div class="ri-title">全球首个工业规模CO₂矿化养护混凝土示范工程</div>
				<div class="content">
					2020年8月29日，“十三五国家重点研发计划”课题“CO₂深度矿化养护制建材关键技术与万吨级工业试验”示范工程在河南的混凝土企业内通过72小时运行。项目通过CO₂矿化养护技术实现了每年1万吨的CO₂温室气体封存，并生产1亿块MU15标准的轻质实心混凝土砖，是全球第一个工业规模CO₂养护混凝土示范工程。
				</div>
				<img src="../static/project/z1.png" >
				<img src="../static/project/z2.png" >
				<img src="../static/project/z3.png" >
				<div class="content">
					该示范工程通过改造一套传统蒸汽养护系统，建立了混凝土制备体系以及新型的CO₂ 矿化养护体系。高浓度CO₂ 来自当地的化工厂排放废气，而90% 的固体原材料则来自当地的工业固废，包括钢渣和粉煤灰等。与传统蒸汽养护工艺相比，本项目无需高温热源，可实现混凝土产品的全生命周期近零的碳排放，并将养护生产效率提高了一倍。该示范工程展示了CO₂ 矿化养护技术在实现大规模、经济性的二氧化碳利用与封存方面前景广阔，并为推动我国相关行业低碳绿色转型提供了新的技术途径。
				</div>
				<img src="../static/project/z4.png" >
				<div class="content">
					    据了解，在2020年9月结束的清洁能源部长级会议上，我国科技部代表汇报了中国近期执行的大型碳捕集利用与封存示范项目。在所汇报的4个大型示范项目中，其中就包括万吨级矿化养护混凝土项目，为中国切实推进应对全球气候变化工作做出重要贡献。
				</div>
			</div>
		</div>
		<div style="width: 100%;margin-top: 251px;">
			<footer-bar></footer-bar>
		</div>
	</div>
</template>

<script>
	import topBar from '@/components/header-bar.vue'
	import utils from '@/utils/common.js'
	import footerBar from '@/components/footer-bar.vue'
	export default{
		components:{
			topBar,
			footerBar
		},
		data(){
			return{
				name:'solution',
				menus:utils.menus,
				active:'solution',
				swiperIndex:1,
			}
		},
		computed:{
			logoImg(){
				// console.log(this.swiperIndex,'lll')
				if(this.swiperIndex == 0){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 1){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 2){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 3){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 4){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 5){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 6){
					return require('../static/home/logo2.png')
				}
				
			},
			fontColor(){
				if(this.swiperIndex == 0){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						color:'#333'
					}
				}
			},
			dtBg(){
				if(this.swiperIndex == 0){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						background:'#333'
					}
				}
			}
		},
		methods:{
			changeOver(m){
				// console.log(m)
				if(m.path == 'solution'){
					m.hover = true
					this.active = m.path
				}
			},
			mouseLeave(m){
				this.menus.forEach(item=>item.hover = false)
				this.active = this.name
			},
			changePath(item){
				if( item.path != 'solution'){
					this.$router.push({
						name:item.path
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wrap {
	    width: 100%;
	    height: 100vh;
	    overflow: hidden;
	    // background: #eeeeee;
		margin: 0;
		padding: 0;
		
	}
		.active-h{
			font-weight: 600;
		}
	.top-box{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		// background: #007AFF;
		.logo{
			width: 42px;
			height: 47px;
			margin-left: 11.25rem;
		}
		.menus-box{
			height: 100%;
			display: flex;
			align-items: center;
			margin-left: 510px;
			position: relative;
		}
		.menus{
			height: 100%;
			width: 114px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 60px;
			font-size: 14px;
			font-weight: 400;
			
			cursor: pointer;
			box-sizing: border-box;
			position: relative;
			.dt-line{
				width: 20px;
				height: 4px;
				// background: #FFFFFF;
				position: absolute;
				bottom: 16px;
			}
			.se-me{
				position: absolute;
				width: 114px;
				height: 108px;
				bottom: -108px;
			}
			.child{
				width: 100%;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 400;
				// color: #FFFFFF;
			}
		}
	}
	
	.header-tag{
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: #075AD3;
		margin-top: 130px;
		margin-left: 175px;
	}
	.body-box{
		width: 100%;
		display: flex;
		align-items: flex-start;
		// justify-content: space-between;
		padding: 0 0 0 175px;
		box-sizing: border-box;
		margin-top: 50px;
		.left-box{
			width: 299px;
			height: 357px;
			background: #FAFBFF;
			padding: 24px 24px 0 24px;
			box-sizing: border-box;
			position: relative;
			margin-right: 60px;
			.tag-title{
				font-size: 20px;
				font-weight: 500;
				color: #1A1A1A;
				margin-bottom: 10px;
			}
			.new-box{
				width: 100%;
				min-height: 64px;
				// sbackground: red;
				
				font-size: 18px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				font-weight: 400;
				color: #1A1A1A;
			}
			.new-border{
				border-bottom: 1px solid #E6E6E6;
			}
			.dot{
				width: 18px;
				height: 18px;
				background: linear-gradient(320deg, rgba(103, 165, 255, 0) 0%, #77AEFF 100%);
				position: absolute;
				border-radius: 50%;
				top: 20px;
				left: 16px;
			}
		}
		.right-box{
			width: 726px;
			padding-left: 60px;
			box-sizing: border-box;
			.ri-title{
				font-size: 28px;
				font-weight: 500;
				color: #1A1A1A;
				margin-bottom: 32px;
				text-align: center;
			}
			.content{
				font-size: 16px;
				font-weight: 400;
				color: #666666;
				text-indent: 30px;
				margin-bottom: 24px;
			}
			img{
				width: 640px;
				height: 100%;
				margin-bottom: 24px;
				margin-left: 20px;
			}
			.imgs{
				width: 726px;
				height: 1000px;
			}
		}
	}
</style>
